import React from "react"
import ProgressBar from "../../components/layouts/progressbar"
import HeadMeta from "../../components/layouts/headmeta"
import Layout from "../../components/layouts/layout"
import CTA from "../../components/cta"

import { changeProgressBarOnScroll } from "../../components/utils/index"

export default class DNAExplorerPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Sales", "DNA Explorer")
  }

  render() {
    const { metadata } = pageData
    return (
      <Layout>
        <div className="product-page" id="dna-explorer">
          <ProgressBar />
          <HeadMeta metadata={metadata} />
          <section id="cta" className="cta bg-warning-light">
            <div className="container">
              <div className="row align-items-center my-5">
                <div className="col-md-10 offset-md-1 text-center">
                  <h2 className="text-center">Explore Your DNA Today.</h2>
                  <p>
                    Uncover your genetic predispositions. Manage your lifestyle
                    with personalised recommendations.
                  </p>
                </div>
              </div>
              <CTA />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "DNA Explorer | Advanx Health",
    description:
      "Discover your predisposition towards certain health risks, nutrition, fitness and inner potential from your DNA.",
    image: "https://www.advanxhealth.com/img/share/dna-explorer.jpg",
    url: "https://www.advanxhealth.com/products/dna-explorer",
  },
  hero_image: "/img/share/dna-explorer.jpg",
}
